
@font-face {
  font-family: 'Lato';
  src: url('../common/fonts/Lato-Regular.ttf');
}
@font-face {
  font-family: 'DMSans';
  src: url('../common/fonts/DMSans-Regular.ttf');
}
@font-face {
  font-family: 'DMSans-Bold';
  src: url('../common/fonts/DMSans-Bold.ttf');
}
@font-face {
  font-family: 'DMSans-BoldItalic';
  src: url('../common/fonts/DMSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'DMSans-Italic';
  src: url('../common/fonts/DMSans-Italic.ttf');
}
@font-face {
  font-family: 'DMSans-Medium';
  src: url('../common/fonts/DMSans-Medium.ttf');
}
@font-face {
  font-family: 'DMSans-MediumItalic';
  src: url('../common/fonts/DMSans-MediumItalic.ttf');
}
