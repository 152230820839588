@import '../theme/_variables.scss';

@font-face {
  font-family: 'archia';
  src: url('../common/fonts/archia-regular.ttf');
}

@font-face {
  font-family: 'archia-bold';
  src: url('../common/fonts/archia-bold.ttf');
}

.flex-details {
  text-align: center;

  .flex-details-side {
    background-color: $empty-space-color;
    flex: 1;
  }

  .payer-details-field {
    margin: 2rem 0 1rem 0;
  }

  .flex-details-title {
    background-color: $general-colour;
    width: 90%;
    margin: 0 auto;
    border: solid #421b68;
  }

  .flex-details-title-text {
    color: $smart-links-payer-title-background-color;
    font-family: "archia", sans-serif;
    font-size: 2.5rem;
    letter-spacing: -0.05em;
    font-weight: normal;
    line-height: 1.15;
    padding: 1rem;
  }

  .input-header {
    font-family: "archia-bold", sans-serif;
    font-size: 1.5rem;
    color: $smart-links-payer-title-background-color;
    letter-spacing: 0;
  }

  .input-box {
    background-color: $general-colour;
    outline: none;
    border: solid $smart-links-payer-title-background-color;
  }

  .input-text {
    font-family: "archia", sans-serif;
    font-size: 3rem;
    letter-spacing: -0.05em;
    color: $smart-links-payer-title-background-color;
    text-align: center;
    width: 50%;
  }

  .input-text-amount {
    font-size: 3rem;
    font-feature-settings: "tnum" 1;
  }

  .input-box-readonly {
    outline: none;
    border: none;
  }

  .error-message-color {
    color: $smart-links-payer-error-color;
  }

  .input-text-error {
    background-color: $error-input-field-background-color;
    border: solid $smart-links-payer-error-color;
  }

  .error-text {
    color: $smart-links-payer-error-color;
    font-family: "archia", sans-serif;
    font-size: 1rem;
    letter-spacing: -0.05em;
    width: 80%;
    margin: 0 auto;
  }

  .flex-details-copyright {
    color: $copy-right-color;
    font-family: "archia", sans-serif;
    font-size: 0.75rem;
    letter-spacing: 0;
    padding: 2rem 2rem 0rem;
  }

  .img-c2p {
    width: 100%;
    max-width: 20rem;
  }

  @media only screen and (max-width: $medium-device-max-width) {
    .flex-details-title-text, .input-text {
      font-size: 2rem
    }
    .img-c2p {
      max-width: 20rem;
    }
  }
}